@import '../abstracts/_variables';

@mixin colorBlendCircles(
  $topRightColor,
  $bottomLeftColor,
  $topRightSize,
  $bottomLeftSize,
  $zIndex: null,
  $swapCorners: false
) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: if($zIndex != null, $zIndex, $zindex-grid-section-background);
  overflow: hidden;

  .colorBlendTopRightCircle,
  .colorBlendBottomLeftCircle {
    position: absolute;
    border-radius: $topRightSize;
    filter: blur(80px);
    opacity: 0.5;
  }

  @if $swapCorners {
    .colorBlendTopRightCircle {
      top: 0;
      left: 0;
      background: $topRightColor;
      width: $topRightSize !important;
      height: $topRightSize !important;
      transform: translate(calc(100% - $topRightSize), 0);
    }

    .colorBlendBottomLeftCircle {
      bottom: 0;
      right: 0;
      background: $bottomLeftColor;
      width: $bottomLeftSize !important;
      height: $bottomLeftSize !important;
      transform: translate(calc(100% - $bottomLeftSize), 0);
    }
  } @else {
    .colorBlendTopRightCircle {
      top: 0;
      right: 0;
      background: $topRightColor;
      width: $topRightSize !important;
      height: $topRightSize !important;
      transform: translate(calc(100% - $topRightSize), 0);
    }

    .colorBlendBottomLeftCircle {
      left: 0;
      bottom: 0;
      background: $bottomLeftColor;
      width: $bottomLeftSize !important;
      height: $bottomLeftSize !important;
      transform: translate(calc(100% - $bottomLeftSize), 0);
    }
  }
}

@mixin commonParagraph($marginTopDesktop: null, $marginTopMobile: null, $color: null) {
  @include desktopPara2();

  @if $marginTopDesktop != null {
    margin-top: $marginTopDesktop;
  }

  @if $color != null {
    color: $color;
  }

  @include respond(medium) {
    @include mobilePara2();

    @if $marginTopMobile != null {
      margin-top: $marginTopMobile;
    }

    @if $color != null {
      color: $color;
    }
  }

  a {
    color: $text-color; // Assuming $text-color is defined somewhere
    text-decoration-line: underline;
  }
}

@mixin removeScrollBar() {
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

@mixin addScroll() {
  height: auto;
  max-height: 90vh;
  overflow: scroll;
  width: auto;
  @include respond(medium) {
    height: 0;
  }
}

@mixin removeDefaultStylesInput() {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

// animations

@mixin slideUpXY {
  @keyframes slideUp {
    from {
      transform: translate(-50%, 100%);
    }
    to {
      transform: translate(-50%, 0%);
    }
  }
}
@mixin slideUpY {
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
}

@mixin absolutePositionHorizontalCenter() {
  left: 50%;
  transform: translateX(-50%);
}
@mixin absolutePositionVerticalCenter() {
  top: 50%;
  transform: translateY(-50%);
}

// Mixins fadeIn
@mixin fadeIn {
  @keyframes fadeIn {
    from {
      top: -50px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-($universalSize-10));
  }
}
