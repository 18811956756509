@import '../../../styles/main.scss';

.productWidgetMainContainer {
  display: flex;
  max-width: $universalSize-980;
  justify-content: space-between;
  align-items: flex-start;
  padding: $universalSize-40 0rem;
  margin: 0 auto;
  min-height: 50.75rem;
  gap: $universalSize-32;

  @media (min-width: 761px) and (max-width: 1125px) {
    padding: $universalSize-40 1rem;
    min-height: 0rem;
  }

  @include respond(medium) {
    flex-direction: column;
    padding: $universalSize-24;
    text-align: center;
    align-items: center;
    height: 100%;
    min-height: 0rem;
  }

  .titleAndKeyFeatures {
    max-width: 60%;

    @include respond(medium) {
      max-width: 100%;
    }
    h2 {
      @include desktopHeading2();

      @include respond(medium) {
        @include mobileHeading2();
      }

      h3 {
        @include desktopHeading3();

        @include respond(medium) {
          @include mobileHeading3();
        }
      }
    }

    .subHeading {
      @include commonParagraph(null, null, $text-color-3);
      margin-top: $universalSize-12;
    }
  }
}

.semLayoutKeyFeaturesDesktopContainer {
  @include respond(medium) {
    display: none !important;
  }
}

.semLayoutKeyFeaturesMobile {
  display: none;

  @include respond(medium) {
    display: flex;
    margin: 0 !important;
  }
}

.keyFeatures {
  margin-top: $universalSize-32;

  @include respond(medium) {
    display: flex;
    justify-content: center;
    gap: 0.9rem;
    align-items: flex-start;
  }

  .keyFeaturesMainItem {
    display: flex;
    flex-basis: 33.33%;

    &:nth-child(2) {
      margin: $universalSize-20 0rem;
    }

    @include respond(medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:nth-child(2) {
        margin: 0rem;
      }
    }

    .keyFeaturesValues {
      margin-left: $universalSize-16;

      @include respond(medium) {
        margin-left: 0rem;
        margin-top: $universalSize-12;
      }

      p {
        @include commonParagraph(null, null, $text-color);

        &:first-child {
          font-weight: 700;
        }

        &:last-child {
          font-weight: 400;
        }
      }
    }
  }
}

.keyFeaturesNoMargin {
  @include respond(medium) {
    margin-top: 0;
  }
}

.mainContainerSemLayout {
  @include respondMin(medium) {
    min-height: fit-content;
    padding: $universalSize-70 0;
  }

  .titleAndKeyFeatures {
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
      @include respondMin(medium) {
        font-size: $universalSize-40;
        color: $white-color;
        line-height: $universalSize-50;
      }
    }
    h1 {
      @include respondMin(medium) {
        font-size: $universalSize-28;
        color: $white-color;
        line-height: $universalSize-36;
      }
    }

    .subHeading {
      @include respondMin(medium) {
        color: $white-color;
        font-weight: $font-weight-card-heading;
      }
    }
    .keyFeatures {
      display: flex;
      align-items: flex-start;

      .keyFeaturesMainItem {
        &:nth-child(2) {
          margin: 0;
        }

        .keyFeaturesValues {
          p {
            font-size: $universalSize-16;
            @include respondMin(medium) {
              color: $white-color;
              line-height: $universalSize-20;
            }
          }
        }

        img {
          width: $universalSize-40;
          height: $universalSize-40;
        }
      }
    }
  }
}

.semHeroImageWeb {
  width: auto;
  align-self: center;
  margin-bottom: $universalSize-16;

  @include respond(medium) {
    display: none;
  }
}

.semHeroImageMweb {
  display: none;
  @include respond(medium) {
    display: block;
    width: auto;
    align-self: center;
    margin: 0;
  }
}

.widgetVisibleMobile {
  display: none;

  @include respond(medium) {
    display: block;
  }
}

.widgetVisibleDesktop {
  display: block;

  @include respond(medium) {
    display: none;
  }
}

.componentContainer {
  @include respond(medium) {
    width: 100%;
  }
}

.ctaButton {
  width: $universalSize-320;
  font-size: $universalSize-18;
  line-height: $universalSize-32;
  font-weight: 600;
  color: $white-color;
  text-align: center;
  background-color: $button-background-color;
  padding: $universalSize-12 $universalSize-24;
  border-radius: $universalSize-12;
  margin-top: $universalSize-40;
  cursor: pointer;

  @include respond(medium) {
    width: 100%;
  }
}

.uidDisclaimer{
  width: $universalSize-320;
  color: $white-color;
  font-size: $universalSize-12;
  padding: $universalSize-12 $universalSize-24;
  text-align: center;

  @include respond(medium) {
    width: 100%;
  }
}

.seoPurpleVariant {
  color: $white-color;
  @include respond(medium) {
    padding: $universalSize-32 $universalSize-24;
  }
  .titleAndKeyFeatures {
    h1 {
      @include respond(medium) {
        font-size: $universalSize-28;
        line-height: $universalSize-28;
      }
      color: $white-color;
    }
    .subHeading {
      @include respond(medium) {
        font-size: $universalSize-16;
      }
      color: $white-color;
    }
    .subHeadingWithLineBreak {
      margin-top: $universalSize-12;
      p {
        @include commonParagraph(null, null, $white-color);
        font-size: $universalSize-22;
        line-height: $universalSize-32;
      }
    }
  }
  @include respondMin(medium) {
    .titleAndKeyFeatures {
      .subHeading {
        font-weight: $font-weight-500;
      }
    }
    .semHeroImageWeb {
      margin-bottom: 0;
    }
  }
}

.semPageHeader {
  @include respond(small) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 74%, rgba(255, 255, 255, 0) 100%);
  }

  @include respondMin(medium) {
    padding-top: $universalSize-120;
  }
}

.lightTheme {
  @include respondMin(medium) {
    color: rgb(255, 255, 255) !important;
  }
}
