@import '../../styles/main.scss';

.uidTag {
  width: auto;
  position: absolute;
  bottom: $universalSize-24;
  left: 12%;
  color: $text-color-3;
  font-size: $universalSize-12;
  font-style: normal;
  font-weight: 400;
  line-height: $universalSize-12;

  @include respond(medium) {
    position: relative;
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: $universalSize-24;
  }
}
