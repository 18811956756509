@import '../../styles/main.scss';

.twoColumnVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;


  @include respond(medium) {
    flex-direction: column;
  }

  .videoPlayerStyles {
    flex-basis: 50%;
    width: 50% !important;

    @include respond(medium) {
     width: 100% !important;
    }

  }

  .twoColumnVideoContent {
    flex-basis: 50%;
    padding: 0 2rem;
    // align-self: center;
    text-align: start;
    

    @include respond(medium) {
     padding: 0;
     margin-top: 2rem;
    }
    
    h2 {
      @include desktopHeading2();

      @include respond(medium) {
        @include mobileHeading2();
      }
    }

    h3 {
      @include desktopHeading3();
  
      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    h3,
    h4,
    h5,
    h6 {
      @include desktopHeading4();
      margin-top: $universalSize-24;

      @include respond(medium) {
        @include mobileHeading4();
        margin-top: $universalSize-16;
      }
    }

    p {
      @include commonParagraph(null, null, $text-color);
      
      &:not(:has(img)):not(:has(strong)) {
        margin: $universalSize-24 0rem 0rem 0rem;

        @include respond(medium) {
          margin: $universalSize-16 0rem 0rem 0rem;
        }
      }
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-top: 10px;
    }

    li {
      @include commonParagraph(null, null, $text-color);
      margin-bottom: 5px;
    }
  }
}
