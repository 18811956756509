@function convertRem($rem-value, $new-base-size: 16, $old-base-size: 10) {
  $px-value: $rem-value * $old-base-size; // Convert rem to pixels using the old base size
  $new-rem-value: calc($px-value / $new-base-size); // Convert pixels to rem using the new base size
  @return $new-rem-value + rem; // Return the new rem value with the 'rem' unit
}

// Generic values in REM with BASE 16px
$universalSize-2: convertRem(0.2);
$universalSize-24: convertRem(2.4);
$universalSize-28: convertRem(2.8);
$universalSize-38: convertRem(3.8);
$universalSize-96: convertRem(9.6);
$universalSize-40: convertRem(4);
$universalSize-44: convertRem(4.4);
$universalSize-48: convertRem(4.8);
$universalSize-980: convertRem(118.5); // We want 74rem with base 14px
$universalSize-16: convertRem(1.6);
$universalSize-18: convertRem(1.8);
$universalSize-64: convertRem(6.4);
$universalSize-60: convertRem(6);
$universalSize-61: convertRem(6.1);
$universalSize-37: convertRem(3.7);
$universalSize-462: convertRem(46.2);
$universalSize-336: convertRem(33.6);
$universalSize-208: convertRem(20.8);
$universalSize-27: convertRem(2.7);
$universalSize-480: convertRem(48);
$universalSize-416: convertRem(41.6);
$universalSize-146: convertRem(14.6);
$universalSize-192: convertRem(19.2);
$universalSize-104: convertRem(10.4);
$universalSize-54: convertRem(5.4);
$universalSize-688: convertRem(68.8);
$universalSize-45: convertRem(4.5);
$universalSize-7: convertRem(0.7);
$universalSize-53: convertRem(5.3);
$universalSize-384: convertRem(38.4);
$universalSize-312: convertRem(31.2);
$universalSize-66: convertRem(6.6);
$universalSize-168: convertRem(16.8);
$universalSize-32: convertRem(3.2);
$universalSize-20: convertRem(2);
$universalSize-33: convertRem(3.3);
$universalSize-200: convertRem(20);
$universalSize-500: convertRem(50);
$universalSize-8: convertRem(0.8);
$universalSize-4: convertRem(0.4);
$universalSize-1: convertRem(0.1);
$universalSize-7: convertRem(0.7);
$universalSize-12: convertRem(1.2);
$universalSize-14: convertRem(1.4);
$universalSize-50: convertRem(5);
$universalSize-380: convertRem(38);
$universalSize-125: convertRem(12.5);
$universalSize-158: convertRem(15.8);
$universalSize-100: convertRem(10);
$universalSize-10: convertRem(1);
$universalSize-11: convertRem(1.1);
$universalSize-5: convertRem(0.5);
$universalSize-15: convertRem(1.5);
$universalSize-34: convertRem(3.4);
$universalSize-36: convertRem(3.6);
$universalSize-510: convertRem(51);
$universalSize-1200: convertRem(120);
$universalSize-120: convertRem(12);
$universalSize-42: convertRem(4.2);
$universalSize-92: convertRem(9.2);
$universalSize-150: convertRem(15);
$universalSize-153: convertRem(15.3);
$universalSize-30: convertRem(3);
$universalSize-6: convertRem(0.6);
$universalSize-9: convertRem(0.9);
$universalSize-675: convertRem(67.5); // D
$universalSize-70: convertRem(7);
$universalSize-72: convertRem(7.2);
$universalSize-74: convertRem(7.3);
$universalSize-300: convertRem(30);
$universalSize-360: convertRem(36);
$universalSize-25: convertRem(2.5);
$universalSize-764: convertRem(76.4);
$universalSize-430: convertRem(43);
$universalSize-432: convertRem(43.2);
$universalSize-450: convertRem(45);
$universalSize-22: convertRem(2.2);
$universalSize-3: convertRem(0.3);
$universalSize-2: convertRem(0.2);
$universalSize-469: convertRem(46.9);
$universalSize-342: convertRem(34.2);
$universalSize-245: convertRem(24.5);
$universalSize-110: convertRem(11);
$universalSize-150: convertRem(16.8);
$universalSize-160: convertRem(16);
$universalSize-190: convertRem(19);
$universalSize-320: convertRem(32);
$universalSize-88: convertRem(8.8);
$universalSize-1024: convertRem(102.4);
$universalSize-26: convertRem(2.6);
$universalSize-36: convertRem(3.6);
$universalSize-535: convertRem(53.5);
$universalSize-560: convertRem(56);
$universalSize-78: convertRem(7.8);
$universalSize-81: convertRem(8.1);
$universalSize-80: convertRem(8);
$universalSize-155: convertRem(15.5);
$universalSize-175: convertRem(17.5);
$universalSize-173: convertRem(17.3);
$universalSize-56: convertRem(5.6);
$universalSize-210: convertRem(21);
$universalSize-270: convertRem(27);
$universalSize-370: convertRem(37);
$universalSize-134: convertRem(13.4);
$universalSize-1320: convertRem(132);
$universalSize-130: convertRem(13);
$universalSize-225: convertRem(22.5);
$universalSize-60: convertRem(6);
$universalSize-41: convertRem(4.1);
$universalSize-44: convertRem(4.4);
$universalSize-82: convertRem(8.2);
$universalSize-615: convertRem(61.5);
$universalSize-93: convertRem(9.3);
$universalSize-46: convertRem(4.6);
$universalSize-152: convertRem(15.2);
$universalSize-232: convertRem(23.2);
$universalSize-235: convertRem(23.5);
$universalSize-1170: convertRem(117);
$universalSize-248: convertRem(24.8);
$universalSize-312: convertRem(31.2);
$universalSize-280: convertRem(28);
$universalSize-1000: convertRem(100);
$universalSize-140: convertRem(14);
$universalSize-46: convertRem(4.6);
$universalSize-471: convertRem(47.1);
$universalSize-420: convertRem(42);
$universalSize-240: convertRem(24);
$universalSize-288: convertRem(28.8);
$universalSize-112: convertRem(11.2);
$universalSize-118: convertRem(11.8);
$universalSize-90: convertRem(9);
$universalSize-44: convertRem(4.4);
$universalSize-49: convertRem(4.9);
$universalSize-52: convertRem(5.2);
$universalSize-84: convertRem(8.4);
$universalSize-66: convertRem(6.6);
$universalSize-220: convertRem(22);
$universalSize-800: convertRem(80);
$universalSize-310: convertRem(31);
$universalSize-600: convertRem(60);
$universalSize-1050: convertRem(105);
$universalSize-592: convertRem(59.2);
$universalSize-388: convertRem(38.8);
$universalSize-548: convertRem(54.8);
$universalSize-350: convertRem(35);
$universalSize-250: convertRem(25);
$universalSize-243: convertRem(24.3);
$universalSize-588: convertRem(58.8);
$universalSize-1155: convertRem(115.5);
$universalSize-894: convertRem(89.4);
$universalSize-1111: convertRem(111.1);
$universalSize-113: convertRem(11.3);
$universalSize-73: convertRem(7.3);
$universalSize-355: convertRem(35.5);
$universalSize-1500: convertRem(150);
$universalSize-1700: convertRem(170);
$universalSize-135: convertRem(13.5);
$universalSize-1061: convertRem(106.1);
$universalSize-400: convertRem(40);
$universalSize-900: convertRem(90);
$universalSize-1100: convertRem(110);
$universalSize-800: convertRem(80);
$universalSize-21: convertRem(2.1);
$universalSize-600: convertRem(60);
$universalSize-488: convertRem(48.8);
$universalSize-700: convertRem(70);
$universalSize-180: convertRem(18);
$universalSize-140: convertRem(14);
$universalSize-198: convertRem(19.8);
$universalSize-68: convertRem(6.8);
$universalSize-1320: convertRem(132);
$universalSize-1400: convertRem(140);
$universalSize-108: convertRem(10.8);
$universalSize-170: convertRem(17);
$universalSize-600: convertRem(60);
$universalSize-640: convertRem(64);


// font-size

$universalSize-148: convertRem(14.8);
$universalSize-588: convertRem(58.8);
$universalSize-390: convertRem(39);
$universalSize-768: convertRem(76.8);
$universalSize-1178: convertRem(117.8);
$universalSize-1178: convertRem(117.8);
$universalSize-35: convertRem(3.5);
$universalSize-576: convertRem(57.6);
$universalSize-576: convertRem(57.6);
$universalSize-1366: convertRem(136.6);

// font-size
$font-size-12: $universalSize-12;
$font-size-14: $universalSize-14;
$font-size-xsmall: $universalSize-16;
$font-size-small: $universalSize-18;
$font-size-20: $universalSize-20;
$font-size-normal: $universalSize-24;
$font-size-28: $universalSize-28;
$font-size-medium: $universalSize-32;
$font-size-40: $universalSize-40;
$font-size-42: $universalSize-42;
$font-size-xnormal: $universalSize-22;
$font-size-medium: $universalSize-32;
$font-size-medium_business: $universalSize-36;
$font-size-xmedium: convertRem(4.4);
$font-size-large: convertRem(5.2);
$font-size-xlarge: convertRem(6.6);
$font-size-78: $universalSize-78;
$font-size-148: $universalSize-148;

// font-weight
$font-weight-item-heading-text: 800;
$font-weight-section-heading: 700;
$font-weight-card-heading: 600;
$font-weight-para-heading: 400;
$font-weight-small-para-heading: 500;
$font-weight-verysmallPara-heading: 300;
$font-weight-500: 500;

// line-height
$line-height-12: $universalSize-12;
$line-height-20: $universalSize-20;
$line-height-22: $universalSize-22;
$line-height-xsmall: $universalSize-24;
$line-height-small: $universalSize-28;
$line-height-32: $universalSize-32;
$line-height-normal: $universalSize-34;
$line-height-40: $universalSize-40;
$line-height-medium: $universalSize-42;
$line-height-xmedium: convertRem(5.4);
$line-height-large: $universalSize-64;
$line-height-xlarge: convertRem(7.8);
$line-height-102: convertRem(10.2);

// heading-size-desktop
$font-size-desktop-h1: $font-size-xlarge;
$font-size-desktop-h2: $font-size-large;
$font-size-desktop-h3: $font-size-medium;
$font-size-desktop-h4: $font-size-normal;

// line-height-heading-desktop
$line-height-desktop-h1: $line-height-xlarge;
$line-height-desktop-h2: $line-height-large;
$line-height-desktop-h3: $line-height-medium;
$line-height-desktop-h4: $line-height-normal;

// paragraph-size-desktop
$font-size-desktop-p1: $font-size-normal;
$font-size-desktop-p2: $font-size-small;
$font-size-desktop-p3: $font-size-xsmall;

// line-height-paragraph-desktop
$line-height-desktop-p1: $line-height-normal;
$line-height-desktop-p2: $line-height-small;
$line-height-desktop-p3: $line-height-xsmall;

// heading-size-mobile
$font-size-mobile-h1: $font-size-xmedium;
$font-size-mobile-h2: $font-size-medium;
$font-size-mobile-h3: $font-size-normal;
$font-size-mobile-h4: $font-size-normal;

// line-height-heading-mobile
$line-height-mobile-h1: $line-height-xmedium;
$line-height-mobile-h2: $line-height-medium;
$line-height-mobile-h3: $line-height-normal;
$line-height-mobile-h4: $line-height-normal;

// paragraph-size-mobile
$font-size-mobile-p1: $font-size-normal;
$font-size-mobile-p2: $font-size-small;
$font-size-mobile-p3: $font-size-xsmall;

// line-height-paragraph-mobile
$line-height-mobile-p1: $line-height-normal;
$line-height-mobile-p2: $line-height-small;
$line-height-mobile-p3: $line-height-xsmall;

// any colors will be added here
$text-color: #040222;
$heading-black-color: #121212;
$subheading-dropdown-color: #4b4b4b;
$button-business-color: #5542ff;
$button-like-categorypage-color: #4f46e5;
$smallCards-heading-color: #1a1a1a;
$smallCards-background-color: #ecefff;
$smallCards-border-color: #e1e1e2;
$accordion-grey-color: #666;
$footer-black-color: #0d0d0d;
$text-color-2: #5b5677;
$text-color-3: #5c5677;
$text-color-4: #151619;
$text-color-5: #434f5a;
$text-color-6: #707f8c;
$purple-color: #5920c5;
$purple-header-color: #5e27c9;
$purple-text-color: #611bcd;
$purple-text-color: #611bcd;
$purple-header-color: #5e27c9;
$purple-text-color: #611bcd;
$purple-header-color: #5e27c9;
$light-purple-color: #faf8ff;
$border-light-color: #e8e8e8;
$button-background-color: #0fa457;
$green-button-active-background-color: #0b753e;
$green-color-10: #ebfbee;
$green-button-disabled-background-color: #e0e0e8;
$background-silver-color: #faf8ff;
$background-articles-silver-color: #f4f5f7;
$background-allarticles-color: #eff1f5;
$link-blue-color: #008de0;
$item-text-grey-color: #414a53;
$item-header-blue-color: #018ee0;
$topic-title-green-color: #44cb86;
$green-color-100: #C1F5C8;
$topic-border-color: #d6d9e0;
$alltopics-background-color: #c3cad24d;
$alltopics-border-grey-color: #c3cad2;
$white-color: #fff;
$plain-white-color: #fff;
$white-transparent: rgba(255, 255, 255, 0.6);
$white-transparent-80: rgba(255, 255, 255, 0.8);
$bottom-floater-bg-color: rgba(18, 18, 18, 1);
$bottom-floater-text-color: #ffab00;
$line-number: 5;
$light-purple: #efe9fb;
$heavy-light-purple: #f8f7fc;
$purple-variant-2: #5b5675;
$purple-variant-3: #8444ea;
$blue-color: #1b73e8;
$blue-color-2: #36354e;
$dark-blue-color: #282e33;
$blue-color-700: #192434;
$light-blue-color: #007bff;
$black-color: black;
$black-color-a-50: #00000080;
$dark-purple-color: #040222;
$white-color-2: #f5f5f5;
$white-color-3: #e7e7f2;
$white-color-4: #e0e0e9;
$white-color-5: #f0e9fd;
$purple-color-3: #7c47e1;
$purple-color-4: #36354c;
$purple-color-10: #f4f5f7;
$purple-color-100: #e1ddff;
$purple-color-200: #d0bdf4;
$purple-color-250: #c3afe9;
$purple-color-300: #6e698c;
$purple-color-500: #6136d9;
$purple-color-550: #523e71;
$purple-color-280: #8468b0;
$purple-color-560: #481aa4;
$purple-color-570: #5920c5;
$purple-color-580: #451999;
$purple-color-600: #331165;
$purple-color-650: #040222;
$purple-color-530: #3c335c;
$purple-color-700: #121212;
$blue-color-100: #d8daff;
$blue-color-500: #0096fb;
$blue-color-700: #020203;
$blue-color-600: #15171a;
$blue-color-800: #141323;
$blue-color-850: #161b2e;
$birght-mint-green: #17ff88;
$white-color-border: #e7e7f0;
$white-color-200: #fafafa;
$purple-color-border: #f0ebff;
$light-blue-color-border: #e4eaf1;
$red-color-50: #f8f0ee;
$red-color-300: #d83d37;
$red-color-800: #1e1e1e;
$light-red-color: #c4c4c4;
$widget-error-text-color: #d83939;
$grey-color-80: #cccccc;
$grey-color-150: #eaeaea;
$grey-color-200: #ebebeb;
$grey-color-800: #414a53;
$grey-subtext-color: #4b4b4b;
$surface-light-grey: #f5f5f5;
$box-shadow-color-1: rgba(54, 53, 76, 0.09);
$box-shadow-color-2: rgba(4, 2, 34, 0.06);
$border-color: rgb(0 0 0 / 0.08);
$orange-color-500: #ff3d00;
$red-color: #d83d37;
$pink-color-500: #d331b8;
$crimson-color-500: #c2255c;
$shimmer-color-2: #f0f0f0;
$shimmer-color-1: #ffffff;
$bright-mint-green-a-40: #17ff8866;
$bright-mint-green-a-20: #17ff8833;
$shadow-color: #36354e14;
$green-color-light: #e7fced;
$background-color-dark: rgba(0, 0, 0, 0.7);
$blush_pink: #ffe9ee;
$scroll-bar-color: #CBCBDB;

$nav-on-hover-color: rgba(85, 66, 255, 0.06);
$red-color: #d83d37;
//z-index
$zindex-grid-root-container: 10;
$zindex-dropdown-container: 20;
$zindex-footer-container: 5;
$zindex-grid-circle-pattern: 1;
$zindex-grid-section-background: -5;
$zindex-grid-full-width-before-content: -10;
$zindex-background-absolute: -1;
$zindex-2: 2;
$zindex-3: 3;
$zindex-4: 4;
$z-index-30: 30;

$max-card-width-size: convertRem(46.8);

// animation-duration
$animation-duration: 1.5s;
