// Define variables for reuse
@import '../../styles/main.scss';

$cell-padding-x: $universalSize-32;
$cell-padding-y: $universalSize-24;
$border-table: $universalSize-1 solid $border-light-color;

// First Variant

.ComparePlans {
  // Apply styles to the table
  max-width: $universalSize-980;
  margin: $universalSize-96 auto;

  h2 {
    @include desktopHeading2();
    text-align: center;
  }


  h3 {
    @include desktopHeading3();
    text-align: center;

    @include respond(medium) {
      @include mobileHeading3();
    }
  }

  p {
    @include desktopPara2();
    text-align: center;
    margin-top: $universalSize-24;
  }
  .tableStyles {
    figure {
      width: 100% !important;
      table {
        margin-top: $universalSize-64;
        border-spacing: $universalSize-16 0;

        thead {
          tr {
            td {
              color: $purple-color;
              background-color: $light-purple-color;
              border-radius: $universalSize-32;
              padding: $cell-padding-x $cell-padding-y;
              width: 20%;
              height: 10%;
              // text-align: center;
              font-weight: 600;
              &:nth-child(2) {
                background-color: $purple-color;
                color: white;
              }
            }
          }
        }
        // Apply styles to each cell
        tbody {
          tr {
            @include desktopPara2();
            // Apply different styles to the first row (heading)
            &:first-child {
              td {
                border: none;
                height: 0;
                padding: 0;
              }
            }

            &:nth-child(2) {
              td {
                border: $border-table;
                border-top-left-radius: $universalSize-32;
                border-top-right-radius: $universalSize-32;
              }
            }

            &:last-child {
              td {
                border: $border-table;
                border-bottom-left-radius: $universalSize-32;
                border-bottom-right-radius: $universalSize-32;
                border-top: 0;
              }
            }

            td {
              padding: $cell-padding-x $cell-padding-x;
              border-bottom: $border-table;
              border-left: $border-table;
              border-right: $border-table;
              width: 20%;
              height: 7%;
              text-align: left;

              a {
                color: blue;
                text-underline-offset: 0.3rem;
              }

              &:first-child {
                font-weight: bold;
              }

              &:nth-child(2) {
                background-color: $light-purple;
                border: $universalSize-1 solid $white-color;
              }
            }
          }
        }
      }
    }

    &.centerTableStyles {
      figure {
        table {
          thead {
            tr {
              td {
                text-align: center;
              }
            } 
          }
        }
      }
    }
  }
}

// second variant

.TableWithMinimalInfo {
  // Apply styles to the table
  max-width: $universalSize-980;
  margin: $universalSize-96 auto;

  h2 {
    @include desktopHeading2();
    text-align: center;
  }


  h3 {
    @include desktopHeading3();
    text-align: center;

    @include respond(medium) {
      @include mobileHeading3();
    }
  }

  p {
    @include desktopPara2();
    text-align: center;
    margin-top: $universalSize-24;
  }
  .tableStyles {
    figure {
      width: 100% !important;
      table {
        margin-top: $universalSize-64;
        border-spacing: $universalSize-16 0;
        // Apply styles to each cell
        thead {
          tr {
            td {
              color: $purple-color !important;
              background-color: $light-purple-color;
              border-radius: $universalSize-32;
              padding: $cell-padding-x $cell-padding-y;
              font-weight: 600;
              width: 20%;
              height: 10%;
              // text-align: center;
            }
          }
        }
        tbody {
          tr {
            @include desktopPara2();
            // Apply different styles to the first row (heading)
            &:first-child {
              td {
                border: none;
                height: 0;
                padding: 0;
              }
            }

            &:nth-child(2) {
              td {
                border: $border-table;
                border-top-left-radius: $universalSize-32;
                border-top-right-radius: $universalSize-32;
              }
            }

            &:last-child {
              td {
                border: $border-table;
                border-bottom-left-radius: $universalSize-32;
                border-bottom-right-radius: $universalSize-32;
                border-top: 0;
              }
            }

            td {
              padding: $cell-padding-x $cell-padding-x;
              border-bottom: $border-table;
              border-left: $border-table;
              border-right: $border-table;
              width: 20%;
              height: 7%;
              text-align: left;
              a {
                color: blue;
                text-underline-offset: 0.3rem;
              }

              &:first-child {
                font-weight: bold;
              }
            }
          }
        }
      }
    }


    &.centerTableStyles {
      figure {
        table {
          thead {
            tr {
              td {
                text-align: center;
              }
            } 
          }
        }
      }
    }
  }

  .footerSection {
    p {
      @include desktopPara2();
      margin-top: $universalSize-64;
      text-align: center;
    }
  }

  button {
    margin: $universalSize-32 auto;
    display: block;
  }
}

.TableWithTwoColumn {
  @extend .ComparePlans;

  .tableStyles {
    figure {
      table {
        thead {
          tr {
            td {
              &:nth-child(2) {
                background-color: $light-purple-color;
                color: $purple-color;
                width: 40%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &:nth-child(2) {
                background: none;
                width: 40%;
                font-weight: normal;
                border: $border-table;
              }
            }
          }
        }
      }
    }
  }
}

.TableWithThreeColumn {
  @extend .TableWithTwoColumn;

  .tableStyles {
    figure {
      table {
        thead {
          tr {
            td {
              &:nth-child(3) {
                background-color: $light-purple-color;
                color: $purple-color;
                width: 40%;
              }
              &:nth-child(2) {
                width: 20%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &:nth-child(3) {
                background: none;
                width: 40%;
                font-weight: normal;
              }
              &:nth-child(2) {
                width: 20%;
                border: $border-table;
              }
            }
          }
        }
      }
    }
  }
}

@include respond(medium) {
  .ComparePlans {
    // Apply styles to the table
    width: 100%;
    margin: $universalSize-64 0;
    padding: 0$universalSize-24;

    h2 {
      @include mobileHeading2();
      text-align: left;
    }

    p {
      @include mobilePara2();
      text-align: left;
      margin-top: $universalSize-16;
    }
    .tableStyles {
      overflow-x: scroll;
      figure {
        table {
          margin-top: $universalSize-32;
          border-spacing: 0;
          thead {
            tr {
              display: none;
            }
          }
          tbody {
            display: flex;
            justify-content: space-around;
            // Apply styles to each cell
            tr {
              @include mobilePara2();
              display: flex;
              flex-direction: column;
              margin-right: $universalSize-20;

              &:first-child {
                display: none;
              }

              &:nth-child(2) {
                td {
                  border-top: 0;
                  border-radius: 0;
                }
              }

              &:last-child {
                td {
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                }
              }

              td {
                padding: $cell-padding-x $cell-padding-x;
                border-bottom: $border-table;
                border-left: $border-table;
                border-right: $border-table;
                width: 260px;
                height: 100%;
                text-align: center;
                @include mobilePara3();

                &::before {
                  content: attr(data-heading);
                  color: $purple-color;
                  font-size: $universalSize-12;
                  line-height: $universalSize-20;
                  font-weight: 400;
                  display: block;
                  padding-bottom: $universalSize-12;
                }

                &:first-child {
                  border-top-right-radius: $universalSize-32;
                  border-top-left-radius: $universalSize-32;
                  // border: $border-table;
                  border-bottom: 0;
                  border-top: $border-table;
                  padding-bottom: $universalSize-70;
                  height: 0;
                }

                &:nth-child(2) {
                  padding-top: 0;
                  border-top: none;

                  @include respond(medium) {
                    padding-top: $universalSize-20;
                  }
                }

                &:last-child {
                  border-bottom-left-radius: $universalSize-32;
                  border-bottom-right-radius: $universalSize-32;
                }
              }
            }
          }
        }
      }
    }

    .tableStyles::-webkit-scrollbar {
      display: none;
    }

    .footerSection {
      p {
        @include mobilePara2();
        margin-top: $universalSize-64;
        text-align: left;
      }
    }
  }

  .TableWithMinimalInfo {
    // Apply styles to the table
    width: 100%;
    margin: $universalSize-64 0;
    padding: 0$universalSize-24;

    h2 {
      @include mobileHeading2();
      text-align: left;
    }

    p {
      @include mobilePara2();
      text-align: left;
      margin-top: $universalSize-16;
    }
    .tableStyles {
      overflow-x: scroll;
      figure {
        table {
          margin-top: $universalSize-32;
          border-spacing: 0;
          thead {
            tr {
              display: none;
            }
          }
          tbody {
            display: flex;
            justify-content: space-around;
            // Apply styles to each cell
            tr {
              @include mobilePara2();
              display: flex;
              flex-direction: column;
              margin-right: $universalSize-20;

              &:first-child {
                display: none;
              }

              &:nth-child(2) {
                td {
                  border-top: 0;
                  border-radius: 0;
                }
              }

              &:last-child {
                td {
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                }
              }

              td {
                padding: $cell-padding-x $cell-padding-x;
                border-bottom: $border-table;
                border-left: $border-table;
                border-right: $border-table;
                width: 260px;
                height: 100%;
                text-align: center;
                @include mobilePara3();

                &::before {
                  content: attr(data-heading);
                  color: $purple-color;
                  font-size: $universalSize-12;
                  line-height: $universalSize-20;
                  font-weight: 400;
                  display: block;
                  padding-bottom: $universalSize-12;
                }

                &:first-child {
                  border-top-right-radius: $universalSize-32;
                  border-top-left-radius: $universalSize-32;
                  // border: $border-table;
                  border-bottom: 0;
                  border-top: $border-table;
                  text-align: center;
                  padding-bottom: $universalSize-70;
                  height: 0;
                }

                &:nth-child(2) {
                  padding-top: 0;
                }

                &:last-child {
                  border-bottom-left-radius: $universalSize-32;
                  border-bottom-right-radius: $universalSize-32;
                }
              }
            }
          }
        }
      }
    }

    .tableStyles::-webkit-scrollbar {
      display: none;
    }

    .footerSection {
      p {
        @include mobilePara2();
        margin-top: $universalSize-32;
        text-align: left;
      }
    }
  }

  .TableWithTwoColumn {
    @extend .ComparePlans;

    .tableStyles {
      figure {
        table {
          margin-top: $universalSize-32;
          tbody {
            tr {
              td {
                &:nth-child(2) {
                  background: none;
                  width: 260px;
                }
              }
            }
          }
        }
      }
    }
  }

  .TableWithThreeColumn {
    @extend .TableWithTwoColumn;

    .tableStyles {
      figure {
        table {
          margin-top: $universalSize-32;
          tbody {
            tr {
              td {
                &:nth-child(3) {
                  background: none;
                  width: 260px;
                }
              }
            }
          }
        }
      }
    }
  }
}
