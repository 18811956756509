@import '../../styles/main.scss';

.videoContainer {
  max-width: $universalSize-980;
  margin: 4rem auto 6rem auto;
  text-align: center;

  .videoHeader {
    h2 {
      @include desktopHeading2();
    }

    h3 {
      @include desktopHeading3();
    }

    p {
      @include desktopPara2();
      margin: $universalSize-24 auto $universalSize-48;
      color: $text-color-2;
    }

    @include respond(medium) {
      h2 {
        @include mobileHeading2();
      }

      h3 {
        @include mobileHeading3();
      }

      p {
        @include mobilePara2();
        margin: $universalSize-16 auto $universalSize-32;
      }
    }
  }

  .videoCta {
    .videoButtonPara {
      margin-top: $universalSize-32;
      p {
        @include desktopPara2();
        margin: $universalSize-48 auto $universalSize-32;
        color: $text-color-2;
      }
    }

    @include respond(medium) {
      .videoButtonPara {
        p {
          @include mobilePara2();
          margin: $universalSize-32 auto $universalSize-16;
        }
      }
    }
  }

  .videoDiv {
    display: flex;
    justify-content: center;
    margin-top: $universalSize-64;
    z-index: 5;
    align-items: center;
    width: 100%;

    .thumbnailContainer {
      position: relative;
      width: $universalSize-764;
      height: $universalSize-430;
      border-radius: $universalSize-40;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .thumbnail {
      width: 100%;
      height: 100%;
      border-radius: $universalSize-40;
      z-index: 1;
    }

    .playIcon {
      position: absolute;
      z-index: 2;
    }

    .videoVimeo {
      width: $universalSize-764;
      height: $universalSize-430;
      border-radius: $universalSize-40;
      position: relative;
      object-fit: cover;
    }

    .video {
      width: 100%;
      height: 100%;
    }

    .pauseIcon {
      position: absolute;
      z-index: 2;
      width: 72px;
      height: 72px;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    .videoVimeo:hover .pauseIcon {
      display: block;
    }
  }

  @include respond(medium) {
    width: 100%;
    padding: $universalSize-24;
    text-align: left;
  }
}
