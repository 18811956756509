@import '../../styles/main.scss';

.quoteRootContainer {
  padding: $universalSize-96 $universalSize-24;

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }

  .quoteContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: $universalSize-40;
    text-align: center;
    padding: 0rem $universalSize-150;

    @include respond(medium) {
      padding: 0rem $universalSize-30;
    }

    margin: 0 auto;
    max-width: $universalSize-980;
    border: $universalSize-1 solid #f4f1f1;

    background: radial-gradient(
        559.4% 87.31% at 93.35% 48.2%,
        rgba(255, 255, 255, 0.56) 0%,
        rgba(255, 255, 255, 0.13) 9.67%,
        rgba(255, 255, 255, 0.37) 80.26%,
        rgba(255, 255, 255, 0.56) 100%
      ),
      radial-gradient(43.43% 92.49% at 65.13% 80.29%, #f896f7 0%, #fff 100%),
      radial-gradient(57.66% 60.98% at 13.57% 21.75%, rgba(72, 168, 252, 0.65) 18.26%, rgba(255, 255, 255, 0.65) 100%),
      radial-gradient(62.39% 93.4% at 82.05% 86.3%, #fa9726 11.36%, #fff 72.8%),
      linear-gradient(262deg, #ffe9e7 4.92%, #feecec 16.09%, #fff 65.18%, #ffe4e2 90.75%);
    background: radial-gradient(
        559.4% 87.31% at 93.35% 48.2%,
        color(display-p3 1 1 1 / 0) 0%,
        color(display-p3 1 1 1 / 0.13) 9.67%,
        color(display-p3 1 1 1 / 0.37) 80.26%,
        color(display-p3 1 1 1 / 0) 100%
      ),
      radial-gradient(
        43.43% 92.49% at 65.13% 80.29%,
        color(display-p3 0.9177 0.606 0.9469) 0%,
        color(display-p3 1 1 1 / 0) 100%
      ),
      radial-gradient(
        57.66% 60.98% at 13.57% 21.75%,
        color(display-p3 0.3856 0.6517 0.9622 / 0.65) 18.26%,
        color(display-p3 1 1 1 / 0) 100%
      ),
      radial-gradient(
        62.39% 93.4% at 82.05% 86.3%,
        color(display-p3 0.9284 0.6124 0.2635) 11.36%,
        color(display-p3 1 1 1 / 0) 72.8%
      ),
      linear-gradient(
        262deg,
        color(display-p3 1 0.9159 0.9098) 4.92%,
        color(display-p3 0.9843 0.9294 0.9255) 16.09%,
        color(display-p3 1 1 1) 65.18%,
        color(display-p3 0.9986 0.8973 0.89) 90.75%
      );

    .quoteBody {
      p {
        @include commonParagraph($universalSize-24, $universalSize-16, $text-color);
      }
    }

    .quoteAuthor {
      @include commonParagraph($universalSize-24, $universalSize-16, $text-color);
      font-size: $universalSize-24;
      font-weight: 600;

      @include respond(medium) {
        font-weight: 600;
      }
    }

    .quoteOccupation {
      @include commonParagraph($universalSize-24, $universalSize-16, $text-color);
      font-size: $universalSize-16;
      font-weight: 400;
      margin-top: $universalSize-8;
      margin-bottom: $universalSize-48;

      @include respond(medium) {
        margin-top: $universalSize-6;
        margin-bottom: $universalSize-28;
      }
    }
  }
}
