@import '../../styles/main.scss';

.container {
  max-width: $universalSize-980;
  margin: 0 auto;
  scroll-behavior: smooth;
  z-index: 14;
  padding-bottom: 1.5rem;
}

@include respond(medium) {
  .container {
    max-width: 60rem;
    margin: 0 auto;
    scroll-behavior: smooth;
    z-index: 14;
    padding: 2.5rem 1.5rem;

    &.isNotSticky {
      min-height: 482px;
    }

    &.isSticky {
      min-height: auto;
    }
  }
}

@include respond(small) {
  .container {
    padding: 2.5rem 1.5rem;
  }
}

.quickLink {
  position: sticky;
  top: 0;
  padding: $universalSize-32;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $universalSize-24;
  border-radius: $universalSize-40;
  background: #f6effc;
  background: color(display-p3 0.9608 0.9373 0.9843);
}

.quickLink.sticky {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1000;
  z-index: 14;
  width: 100%;
  padding: $universalSize-12 $universalSize-24; /* Slim down padding when sticky */
  border-radius: 0;
  animation: slideIn 0.5s ease forwards;

  @include respond(small) {
    padding: 0.25rem 1rem;
  }
}

.linkSvg {
  margin-right: $universalSize-10;
}

.quickLink.sticky + .content {
  margin-top: 120px; /* Adjust as needed based on the height of the sticky quick link section */
}

.expand {
  display: block;
  font-size: $universalSize-18;
  font-style: normal;
  font-weight: 500;
  line-height: $universalSize-28; /* 155.556% */
  background: linear-gradient(91deg, #6833e2 -44.74%, #e60fbd 74.96%);
  background: linear-gradient(
    91deg,
    color(display-p3 0.3804 0.2118 0.851) -44.74%,
    color(display-p3 0.8275 0.1922 0.7216) 74.96%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
}

.heading {
  color: #040224;
  font-size: $universalSize-32;
  font-style: normal;
  font-weight: 600;
  line-height: $universalSize-42;
  display: flex;
  align-items: center;
  margin-bottom: $universalSize-16;
}

.ulBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ulBox ul {
  display: none;
}

.ulBox.show ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.ulBox ul {
  padding: 0;
  justify-content: space-between;
}

.ulBox li {
  display: flex;
  align-items: center;
  margin: $universalSize-12 0;
  display: flex;
  padding: $universalSize-12 $universalSize-16;
  gap: $universalSize-12;
  border-radius: $universalSize-32;
  background: #fff;
  /* font */
}

.ulBox .liLink {
  color: #040224;
  font-kerning: none;
  font-size: $universalSize-16;
  font-style: normal;
  font-weight: 400;
  line-height: $universalSize-24; /* 150% */
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ulBox .liLink span {
  padding: $universalSize-8 $universalSize-12;
  font-size: $universalSize-16;
}

@media (min-width: 768px) {
  .ulBox {
    /* justify-content: space-between; */
  }

  .ulBox ul:first-child {
    display: flex;
    flex-wrap: wrap;
  }

  .ulBox ul:first-child li {
    /* width: 100%;
    max-width: 32.25rem */
    flex-basis: 48.5%;
  }

  .ulBox ul:last-child {
    flex-wrap: wrap;
  }

  .ulBox ul:last-child li {
    /* width: 100%;
    max-width: 32.25rem */
    flex-basis: 48.5%;
  }

  .heading {
    cursor: pointer;
  }
  .dropdownBtn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ulBox ul:first-child {
    display: block;
  }

  .ulBox .liLink {
    text-decoration: none;
  }

  .ulBox.show ul {
    flex-direction: column;
  }

  .ulBox .liLink {
    color: black;
    font-size: $universalSize-14;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-20;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .ulBox .liLink .liLink {
    justify-content: flex-start;
  }
  .expand {
    display: flex;
  }
  .quickLink {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .stickyLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    max-width: 100%;
    align-items: center;
    gap: $universalSize-8;
    background: #36354e;
  }

  .stickyLink .heading {
    display: none;
  }

  /* .stickyLink .ulBox {
    display: flex;
    flex-wrap: nowrap;
  } */

  .sticky .ulBox li {
    margin: 0 $universalSize-10;
    display: flex;
    padding: $universalSize-4 $universalSize-6;
    justify-content: center;
    align-items: center;
    border-radius: $universalSize-32;
    /* background: #fff; */
    font-size: $universalSize-12;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-16;
    background-color: #2c2b46;
  }

  .sticky .ulBox .liLink {
    text-decoration: none;
    color: white;
    text-decoration: none;
  }

  .sticky .ulBox li img {
    display: none;
  }

  .sticky .ulBox ul:first-child {
    flex-wrap: nowrap;
  }

  /* .sticky .ulBox ul:first-child li {
    width: 100%;
  } */

  .stickyLink .expand {
    display: flex;
    width: 20%;
    display: flex;
    padding: $universalSize-4 $universalSize-6;
    justify-content: center;
    align-items: center;
    gap: $universalSize-8;
    border-radius: $universalSize-32;
    border: 1px solid #e8e8e8;
    -webkit-text-fill-color: white;
  }

  /* .stickyLink .ulBox ul:last-child {
    width: 240px;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    background: #2c2b46;
    background: color(display-p3 0.1725 0.1686 0.2667);
  } */

  /* .stickyLink .ulBox ul:last-child {
    flex-wrap: nowrap;
    flex-direction: column;
    width: 40%;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  } */

  .stickyLink .ulBox ul:last-child {
    position: absolute;
    top: calc(100% + 0px);
    right: 0;
    width: 40%;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 40%;
    padding: $universalSize-8 0rem;
    align-items: center;
    background: #2c2b46;
    max-height: 300px;
    overflow: scroll;
  }

  .stickyLink .ulBox ul:last-child .liLink {
    padding: $universalSize-8 $universalSize-16;
    width: 99%;
    background: none;
    justify-content: flex-start;
    padding: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: $universalSize-12;
    line-height: $universalSize-16;
    font-weight: 400;
  }

  .stickyLink li.selectedLi {
    background-color: white;
    font-size: $universalSize-120;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-16;
    color: black !important;
    text-decoration: none;
  }
  .sticky .ulBox ul:last-child .liLink {
    text-decoration: none;
    color: #ffffff;
    opacity: 60%;
    padding: $universalSize-24 0rem;
  }

  .sticky .ulBox ul:last-child .liLink:hover {
    color: #ffffff;
    opacity: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 3px solid green;
  }

  .sticky .ulBox ul:last-child li.selectedLi {
    opacity: 100;
    color: white !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 3px solid green;
  }
  /* .sticky .ulBox ul:last-child li:hover {
    background-color: yellow;
    opacity: 100;
    color: white;
    border-left: 2px solid green;
  } */

  .ulBox .liLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .sticky .liLink span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent wrapping to a new line */
    display: block; /* Ensure the ellipsis is shown properly */
    padding: $universalSize-8 $universalSize-12;
    font-size: $universalSize-16;
  }
}

@include respond(small) {
  .sticky .liLink span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent wrapping to a new line */
    display: block; /* Ensure the ellipsis is shown properly */
    padding: $universalSize-8 0rem;
    font-size: $universalSize-16;
  }

  .stickyLink .liLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .stickyLink .ulBox .liLink:hover {
    border-left: 3px solid green;
    opacity: 100%;
  }

  .sticky .ulBox ul li.selectedLi {
    opacity: 100;
    border-left: 3px solid green;
  }
}
/* Add some margin space between the fixed quick link section and the content below it */

@media (max-width: 768px) {
  .stickyLink {
    background-color: #36354e;
  }
  .stickyLink .expand {
    display: none;
  }
  .stickyLink .container {
    width: 100%;
  }
  .stickyLink .linkSvg {
    display: none;
  }
  .stickyLink .ulBox {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  .stickyLink .showDropdown {
    max-height: 200px;
    overflow: scroll;
  }

  .stickyLink .ulBox ul {
    display: block;
  }
  .stickyLink .ulBox .liLink {
    text-decoration: none;
    color: #ffffff;
    opacity: 60%;
  }

  .stickyLink .ulBox .liLink:hover {
    border-left: 3px solid green;
    opacity: 100%;
  }

  .stickyLink .quickLink {
    padding: $universalSize-16 $universalSize-24;
    align-items: center;
    gap: $universalSize-8;
    background: #36354e;
    border-radius: 0;
    padding: 0;
  }
  .stickyLink .heading {
    display: none;
  }
  .stickyLink .ulBox.show ul {
    flex-direction: column;
  }
  .stickyLink .ulBox ul:first-child li:first-child {
    display: flex;
    justify-content: space-between;
  }
  .stickyLink .ulBox li {
    display: flex;
    padding: $universalSize-12 $universalSize-24;
    align-items: center;
    gap: $universalSize-8;
    align-self: stretch;
    border-radius: 0;
    background-color: #36354e;
  }
  .stickyLink .liLink {
    width: 100%;
    margin: 0;
    text-decoration: none;
  }

  .stickyLink .ulBox .liLink {
    justify-content: inherit;
  }
  .ulBox .liLink {
    display: flex;
    justify-content: initial;
  }

  .stickyLink .mobileHeadingLi {
    display: block;
    font-size: $universalSize-14;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-20;
    padding: $universalSize-12 $universalSize-24;
    width: 100%;
    /* border-left: 2px solid green; */
  }
  .mobileHeadingLi li {
    color: white;
    display: flex;
    justify-content: space-between;
  }
}

.mobileHeadingLi {
  display: none;
}

.seeMoreLessContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $universalSize-24;
}

.seeMoreLessText {
  background: linear-gradient(91deg, #6833e2 -44.74%, #e60fbd 74.96%);
  background: linear-gradient(
    91deg,
    color(display-p3 0.3804 0.2118 0.851) -44.74%,
    color(display-p3 0.8275 0.1922 0.7216) 74.96%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 0.75rem;
}

.invertImage {
  transform: scale(1, -1);
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
