@import '../../styles/main.scss';

.testimonialRootContainer {
  padding: $universalSize-96 $universalSize-24;

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }

  background: linear-gradient(60deg, color(display-p3 1 1 1) 10%, color(display-p3 1 1 1 / 0.00) 22%), linear-gradient(0deg, color(display-p3 1 1 1) 0%, color(display-p3 1 1 1 / 0.00) 91%), radial-gradient(57.93% 52.99% at 56.11% 47.99%, color(display-p3 1 1 1 / 0.00) 0%, color(display-p3 0.4392 0.8118 1 / 0.19) 50%, color(display-p3 1 1 1 / 0.00) 100%), linear-gradient(236deg, color(display-p3 1 1 1 / 0.00) 0.9%, color(display-p3 0.3804 0.298 1 / 0.30) 24.99%, color(display-p3 1 1 1 / 0.00) 69.33%), linear-gradient(235deg, color(display-p3 1 1 1 / 0.00) 44.89%, color(display-p3 0.4863 0.0784 0.9882 / 0.23) 62.23%);

  .sectionHeading {
    text-align: center;
    margin: 0 auto;
    max-width: $universalSize-980;

    @include respond(medium) {
      text-align: left;
    }

    h2 {
      @include desktopHeading2();

      @include respond(medium) {
        @include mobileHeading2();
      }
    }


    h3 {
      @include desktopHeading3();

      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    p {
      @include commonParagraph($universalSize-24, $universalSize-16, $text-color-2);
    }
  }

  .imageContainer {
    text-align: center;
    margin: 0 auto;
    max-width: $universalSize-980;

    @include respond(medium) {
      text-align: left;
    }
  }

  .testimonialCard {
    display: inline-block;
    width: $universalSize-360;
    border-radius: $universalSize-24;
    background: $plain-white-color;
    padding: $universalSize-24;
    margin-top: $universalSize-64;
    margin-left: $universalSize-25;

    .cardProfile {
      display: flex;
      align-items: center;

      .cardProfilePhoto {
        img {
          border-radius: 48px;
        }
      }
      .cardProfileInfo {
        margin-left: $universalSize-16;
        p {
          &:first-child {
            color: #040224;
            font-size: $universalSize-16;
            font-style: normal;
            font-weight: 600;
            line-height: $universalSize-24;
          }

          &:last-child {
            color: #5c5677;
            font-size: $universalSize-14;
            font-style: normal;
            font-weight: 300;
            line-height: $universalSize-20;
          }
        }
      }
    }

    .customerReview {
      @include commonParagraph(null, null, $text-color);
      height: $universalSize-150;
      overflow: hidden;
      text-overflow: ellipsis;  
      margin-top: $universalSize-24;
    }
  }
}
