@import '../../styles/main.scss';

.rootContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  @include respond(medium) {
    display: none;
  }
}

.onlyBackgroundGradientWeb {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $purple-header-color;
  z-index: -1;

  @include respond(medium) {
    &:not(&.purpleBgMWeb) {
      display: none;
    }
  }
}

.mwebImageBackgroundGradient {
  display: none;

  @include respond(small) {
    display: block;
    position: relative;
    // width: 26.875rem;
    height: 23.5rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(359deg, #ffffff 0%, #ffffff 11.06%, #585858 31.7%, #0d0d0e 78.17%, #ffffff 103.96%);
    }
  }

  .backgroundImageMweb {
    @include respond(small) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.mwebImageBackgroundGradient.noGradient::before {
  @include respond(small) {
    background: none;
  }
}

.webImageBackgroundGradient {
  @include respond(small) {
    display: none;
  }
}

.childrenContainer {
  position: relative;

  .backgroundImageMwebChild {
    display: none;

    @include respond(medium) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(53deg, color(display-p3 1 1 1 / 0) 78%, color(display-p3 1 1 1 / 0.69) 100%),
        linear-gradient(60deg, color(display-p3 1 1 1) 10%, color(display-p3 1 1 1 / 0) 22%),
        linear-gradient(0deg, color(display-p3 1 1 1) 0%, color(display-p3 1 1 1 / 0) 91%),
        linear-gradient(231deg, color(display-p3 0.3137 0 0.9451 / 0.3) 4.93%, color(display-p3 1 1 1 / 0) 54.6%),
        linear-gradient(244deg,
          color(display-p3 1 1 1 / 0) 20.19%,
          color(display-p3 0.9843 0.2902 0.7294 / 0.33) 76.91%,
          color(display-p3 1 1 1 / 0) 112.66%);
      transform: rotate(180deg);
      z-index: -1;
    }
  }
}

.semChildrenContainer {
  position: absolute;
  top: 22rem;
}