@import '../../styles/main.scss';

.stepCardsRootContainer {
  position: relative;
  z-index: $zindex-grid-root-container;

  .colorBlendParent {
    background: rgb(245 239 251/1);
    @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 320px, 320px);

    @include respond(medium) {
      @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 214px, 214px);
    }
  }

  .stepCardsMainContainer {
    position: relative;
    z-index: $zindex-grid-root-container;
    padding: $universalSize-96 $universalSize-24;

    @include respond(medium) {
      padding: $universalSize-40 $universalSize-24;
    }

    .sectionHeading {
      text-align: center;
      margin: 0 auto;
      max-width: $universalSize-980;

      @include respond(small) {
        text-align: left;
      }

      h2 {
        @include desktopHeading2();

        @include respond(medium) {
          @include mobileHeading2();
        }
      }

      h3 {
        @include desktopHeading3();

        @include respond(medium) {
          @include mobileHeading3();
        }
      }

      p {
        @include commonParagraph($universalSize-24, $universalSize-16, $text-color-2);
      }
    }

    .sectionCta {
      display: flex;
      flex-direction: column;
      gap: 32px;

      max-width: $universalSize-980;
      margin: 0 auto;
      text-align: center;

      p {
        @include commonParagraph($universalSize-64, $universalSize-32, $text-color-2);

        @include respond(small) {
          text-align: left;
        }
      }
    }

    .halfWidthRootContainer {
      display: flex;
      flex-direction: row;
      gap: $universalSize-10;
      width: 100%;
      margin: 0 auto;
      max-width: $universalSize-980;
      margin-top: $universalSize-64;

      @include respond(medium) {
        margin-top: $universalSize-32;
        flex-direction: column;
      }
    }

    .halfWidthContainer {
      flex-basis: 50%;

      @include respond(medium) {
        width: 100%;
        order: 1;
        margin-top: $universalSize-24;
        margin-left: 0rem;
      }
    }

    .imageContainer {
      flex: 1;
    }

    .imageWrapper {
      width: 100%;
      height: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: $universalSize-40;

      @include respond(medium) {
        height: 95%;
      }
    }

    .fieldSet {
      border: none;

      .checkBoxLabel {
        position: relative;
        cursor: pointer;

        .toggleInput {
          position: absolute;
          opacity: 0; // visually hidden
          cursor: pointer;
          z-index: 3;
          width: 1px; // Ensure it is still focusable
          height: 1px;
          overflow: hidden;
        }

        .cardItem {
          padding: $universalSize-32;
          border-radius: $universalSize-32;
          border: $universalSize-1 solid $plain-white-color;
          background: rgba(255, 255, 255, 0.5);
          max-width: 34rem;
          width: 100%;

          &.addMargin {
            margin-bottom: $universalSize-16;

            &:hover {
              opacity: 1;
              transform: scale(1.01);
            }
          }

          &.addHeight {
            height: $universalSize-675;

            @include respond(medium) {
              height: auto;
            }
          }

          @include respond(medium) {
            padding: $universalSize-24;
            max-width: 100%;
          }

          .cardContent {
            h3 {
              @include desktopHeading3();
              margin-top: $universalSize-24;

              @include respond(medium) {
                @include mobileHeading3();
                margin-top: $universalSize-16;
              }
            }

            h4,
            h5,
            h6 {
              @include desktopHeading4();
              margin-top: $universalSize-16;

              @include respond(medium) {
                @include mobileHeading4();
              }
            }

            ul {
              padding: 0;
              margin-top: $universalSize-40;
              margin-left: $universalSize-40;

              li {
                @include commonParagraph(null, null, $text-color);
              }
            }

            p {
              @include commonParagraph(null, null, $text-color);

              &:not(:has(img)) {
                margin: $universalSize-16 0rem 0rem 0rem;
                @include respond(medium) {
                  margin: $universalSize-8 0rem 0rem 0rem;
                }
              }
            }
          }
        }
      }
    }

    .cardStepIcon {
      display: flex;
      padding: $universalSize-8 $universalSize-16;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      background: #7353ff;
      width: max-content;

      p {
        color: #fff;
        font-size: $universalSize-18;
        font-style: normal;
        font-weight: 600;
        line-height: $universalSize-28;
        margin-left: $universalSize-8;
      }
    }
  }
}
