@import '../abstracts/_variables';

@mixin typography(
  $font-size: $font-size-normal,
  $font-weight: $font-weight-section-heading,
  $line-height: $line-height-normal,
  $color: $text-color
) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
}

// Heading mixins
@mixin desktopHeading1($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-h1,
    $font-weight: $font-weight-section-heading,
    $line-height: $line-height-desktop-h1,
    $color: $color
  );
}

@mixin desktopHeading2($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-h2,
    $font-weight: $font-weight-section-heading,
    $line-height: $line-height-desktop-h2,
    $color: $color
  );
}

@mixin desktopHeading3($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-h3,
    $font-weight: $font-weight-card-heading,
    $line-height: $line-height-desktop-h3,
    $color: $color
  );
}

@mixin desktopHeading4($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-h4,
    $font-weight: $font-weight-card-heading,
    $line-height: $line-height-desktop-h4,
    $color: $color
  );
}

@mixin mobileHeading1($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-h1,
    $font-weight: $font-weight-section-heading,
    $line-height: $line-height-mobile-h1,
    $color: $color
  );
}

@mixin mobileHeading2($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-h2,
    $font-weight: $font-weight-section-heading,
    $line-height: $line-height-mobile-h2,
    $color: $color
  );
}

@mixin mobileHeading3($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-h3,
    $font-weight: $font-weight-card-heading,
    $line-height: $line-height-mobile-h3,
    $color: $color
  );
}

@mixin mobileHeading4($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-h4,
    $font-weight: $font-weight-card-heading,
    $line-height: $line-height-mobile-h4,
    $color: $color
  );
}

@mixin desktopPara1($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-p1,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-desktop-p1,
    $color: $color
  );
}

@mixin desktopPara2($color: $text-color, $font-weight: 600) {
  @include typography(
    $font-size: $font-size-desktop-p2,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-desktop-p2,
    $color: $color
  );
}

@mixin desktopPara3($color: $text-color) {
  @include typography(
    $font-size: $font-size-desktop-p3,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-desktop-p3,
    $color: $color
  );
}

@mixin mobilePara1($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-p1,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-mobile-p1,
    $color: $color
  );
}

@mixin mobilePara2($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-p2,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-mobile-p2,
    $color: $color
  );
}

@mixin mobilePara3($color: $text-color) {
  @include typography(
    $font-size: $font-size-mobile-p3,
    $font-weight: $font-weight-para-heading,
    $line-height: $line-height-mobile-p3,
    $color: $color
  );
}

@mixin respond($breakpoint) {
  @if ($breakpoint == x-small) {
    @media (max-width: 320px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 480px) {
      @content;
    }
  }
  @if ($breakpoint == medium) {
    @media (max-width: 760px) {
      @content;
    }
  }
  @if ($breakpoint == large) {
    @media (max-width: 1024px) {
      @content;
    }
  }
  @if ($breakpoint == x-large) {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if ($breakpoint == maxi) {
    @media (max-width: 1201px) {
      @content;
    }
  }
}

@mixin respondRange($min-width, $max-width) {
  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin respondMin($breakpoint) {
  @if ($breakpoint ==x-small) {
    @media (min-width: 320px) {
      @content;
    }
  }

  @if ($breakpoint ==small) {
    @media (min-width: 480px) {
      @content;
    }
  }

  @if ($breakpoint ==medium) {
    @media (min-width: 760px) {
      @content;
    }
  }

  @if ($breakpoint ==large) {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @if ($breakpoint ==x-large) {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin respondMinWithValue($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respondMaxWithValue($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}
