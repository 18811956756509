@import '../../styles/main.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5); // Transparent black overlay

  .modal {
    @include respond(medium) {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top-left-radius: $universalSize-24;
      border-top-right-radius: $universalSize-24;
      background-color: white;
      transition: transform 0.3s ease-in-out;
    }

    @media (min-width: 761px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // width: 32rem;
      max-width: calc(100% - 2rem);
      background-color: white;
      z-index: 16;
      transition: transform 0.3s ease-in-out;
      border-top-left-radius: $universalSize-24;
      border-top-right-radius: $universalSize-24;
      border-bottom-left-radius: $universalSize-24;
      border-bottom-right-radius: $universalSize-24;
    }

    .modalHeader {
      .close {
        color: #000;
        padding: $universalSize-20;
        position: absolute;
        font-size: 18px;
        right: 0;
        top: 0;
        z-index: 20;
      }

      .close:hover,
      .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .modalContent {
      p {
        color: black;
      }
    }
  }

  .isHealthWidget{
    width: 45%;
  }
}
